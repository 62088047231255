import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import styled from 'styled-components'

import Title from 'components/common/Title'
import Text from 'components/common/Text'
import Button from 'components/common/Button'
import SkeletonBox from 'components/common/skeletons/SkeletonBox'

import colors from 'utility/colors'
import ShieldLogoWhite from 'assets/ShieldLogoWhite.png'

const StyledContainer = styled(Container)`
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
  min-height: ${(props) => props.height};
  display: flex;
  align-items: center;
  background-color: ${colors.PRIMARY};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(${(props) => props.image});
`

const BlogHero = ({
  title,
  xs = 12,
  md = 7,
  withLogo = false,
  bodyStyle = {},
  body,
  cta,
  firstColStyle = {},
  titleStyle = {},
  onClickCta,
  backgroundImage,
  height = '85vh',
  children,
}) => (
  <StyledContainer fluid height={height} image={backgroundImage}>
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'black',
        opacity: '.5',
      }}
    />
    <Container style={{ maxWidth: 1200, paddingBottom: 30 }}>
      <Row>
        <Col
          xs={{ span: xs, order: 1 }}
          md={{ span: md, order: 12 }}
          style={firstColStyle}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {withLogo ? (
              <img
                alt={'Home Tax Shield hero'}
                src={ShieldLogoWhite}
                style={{ marginRight: 15, width: 77, height: 95 }}
                className="d-none d-lg-block"
              />
            ) : null}
            {title ? (
              <Title
                size={38}
                style={{
                  fontWeight: 'bold',
                  color: colors.WHITE,
                  ...titleStyle,
                }}
              >
                {title}
              </Title>
            ) : (
              <SkeletonBox width={'50%'} height={'40px'} />
            )}
          </div>
          {body ? (
            <Text
              color={colors.WHITE}
              style={{
                marginTop: 10,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                ...bodyStyle,
              }}
            >
              {body}
            </Text>
          ) : null}

          {onClickCta && (
            <Button
              id={'hero-cta'}
              onClick={onClickCta}
              background={colors.ACCENT}
              style={{
                width: 'auto',
                paddingLeft: 20,
                paddingRight: 20,
                marginTop: 10,
              }}
            >
              {cta}
            </Button>
          )}
        </Col>
        {children}
      </Row>
    </Container>
  </StyledContainer>
)

export default BlogHero
