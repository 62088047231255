import React from 'react'
import styled from 'styled-components'
import { createMarkup } from 'utility/string'
import colors from 'utility/colors'

const StyledContent = styled.div`
  h1 {
    color: ${colors.PRIMARY};
    font-size: 44px;
    line-height: 48px;
    margin: 0 0 30px;
    font-weight: bold;
  }

  h2 {
    color: ${colors.PRIMARY};
    font-size: 32px;
    font-weight: normal;
    line-height: 42px;
    margin: 0 0 24px;
    padding: 12px 0 0;
    font-weight: bold;
  }

  h3 {
    color: ${colors.ACCENT};
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    margin: 0 0 24px;
    padding: 12px 0 0;
    text-transform: uppercase;
    font-weight: bold;
  }

  h4 {
    color: ${colors.ACCENT};
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    margin: 0 0 24px;
    padding: 12px 0 0;
    text-transform: uppercase;
    font-weight: bold;
  }

  h5 {
    color: ${colors.ACCENT};
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
    margin: 0 0 24px;
    padding: 12px 0 0;
    text-transform: uppercase;
    font-weight: bold;
  }

  h6 {
    color: ${colors.ACCENT};
    font-size: 12px;
    font-weight: bold;
    line-height: 24px;
    margin: 0 0 24px;
    padding: 12px 0 0;
    text-transform: uppercase;
    font-weight: bold;
  }

  p {
    color: #2f2f2f;
    font-size: 17px;
    line-height: 24px;
    text-overflow: initial;
  }
  li {
    color: ${colors.PRIMARY};
    font-size: 17px;
    line-height: 24px;
    margin: 6px 0px;
    text-overflow: initial;
    padding: 0px 0 0;
  }
  ol,
  ul {
    padding-left: 20px;
    list-style: square;
  }
  a {
    color: ${colors.GREEN} !important;
    padding-bottom: 5px !important;
    font-size: 16px !important;
    line-height: 24px !important;
  }
  a:hover {
    color: ${colors.GREEN};
  }

  .aligncenter {
    text-align: center;
  }
`

const BlogContent = ({ children }) => {
  const childrenWithCorrectedImages = children.replaceAll(
    '/wp-content/uploads',
    'https://wp.hometaxshield.com/wp-content/uploads',
  )

  return (
    <StyledContent
      dangerouslySetInnerHTML={createMarkup(childrenWithCorrectedImages)}
    />
  )
}
export default BlogContent
