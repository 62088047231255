import Page from 'components/common/Page'
import { Container, Row, Col } from 'react-bootstrap'
import Title from 'components/common/Title'
import Text from 'components/common/Text'

export default function Custom404() {
  return (
    <Page footer wedge>
      <Container>
        <Row>
          <Col style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ marginTop: 100, marginBottom: 100 }}>
              <Title align="center">404 - Page Not Found</Title>
              <Text align="center">
                Sorry, the page you requested could not be found.
              </Text>
            </div>
          </Col>
        </Row>
      </Container>
    </Page>
  )
}
